<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <b-card title="Card Title" no-body>
          <b-card-header
            header-tag="nav"
            header-bg-variant="transparent"
            header-class="pt-4"
          >
            <b-nav card-header tabs justified>
              <b-nav-item to="/analytics/" exact exact-active-class="active">
                Gerocare
              </b-nav-item>
              <b-nav-item
                to="/analytics/revenue-expenses"
                exact
                exact-active-class="active"
              >
                Revenue & Expenses
              </b-nav-item>
              <b-nav-item
                to="/analytics/medical-requests"
                exact
                exact-active-class="active"
              >
                Medical Requests
              </b-nav-item>
              <b-nav-item
                to="/analytics/incidents"
                exact
                exact-active-class="active"
              >
                Incidents
              </b-nav-item>
              <b-nav-item
                to="/analytics/visits"
                exact
                exact-active-class="active"
              >
                Visits
              </b-nav-item>
              <b-nav-item
                to="/analytics/elderly-calls"
                exact
                exact-active-class="active"
              >
                Elderly Calls
              </b-nav-item>
            </b-nav>
          </b-card-header>

          <b-card-body style="padding-top: 30px !important">
            <router-view></router-view>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Analytics",
};
</script>
